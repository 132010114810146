<template>
	<div v-if="viewAs.AccountID != null" class="va-container d-flex align-items-center m-0 py-2 px-4">
		<div class="symbol-label d-flex align-items-center mr-3">
			<img v-if="viewAs.avatar" :src="viewAs.avatar" />
			<inline-svg v-if="!viewAs.avatar" src="/media/svg/icons/Communication/Contact1.svg" />
		</div>
		<div class="mr-3">
			<div class="view-as-header">Viewing As</div>
			<h3 class="my-0">{{ viewAs.username }}</h3>
		</div>
		<router-link :to="{ name: 'admineditaccount', params: { id: this.viewAs.AccountID } }">
			<div class="btn btn-icon btn-sm btn-outline-dark mr-2" v-b-tooltip.hover.bottom="'Edit User'">
				<i class="flaticon-interface-7"></i>
				<!-- <span class="svg-icon svg-icon-xl svg-icon-dark">
				<inline-svg src="/media/svg/icons/Design/Edit.svg" @click="editAccount" />
			</span> -->
			</div>
		</router-link>
		<div class="btn btn-icon btn-outline-dark btn-sm" v-b-tooltip.hover.bottom="'Stop Viewing As User'" @click="stopViewingAs">
			<i class="flaticon2-cross"></i>
			<!-- <span class="svg-icon">
				<inline-svg src="/media/svg/icons/Code/Stop.svg" @click="stopViewingAs" />
			</span> -->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';
import { REMOVE_VIEWAS } from '@/core/services/store/viewas.module';

export default {
	methods: {
		stopViewingAs() {
			this.$store.dispatch(REMOVE_VIEWAS).then(() => {
				this.$router.go();
			});
		},
		editAccount() {
			router.push({ name: 'admineditaccount', params: { id: this.viewAs.AccountID } });
		},
	},
	computed: {
		...mapGetters(['viewAs']),
	},
};
</script>

<style scoped lang="scss">
.va-container {
	background: #fec82e;
	border-radius: 0 0 7px 7px;
}
.view-as-header {
	font-weight: 400;
	font-size: 10px;
}
.symbol-label {
	position: relative;
}
.symbol-label img {
	width: 35px;
	height: 35px;
	border-radius: 5px;
}
#viewing-as-icons .btn.btn-icon:hover {
	background-color: #fff;
}
</style>
